import React from 'react'
import {
    loadUserVacations,
    addVacation,
    deleteVacation,
    editVacation,
    startVacationEdit,
} from '../../store/actions/vacation.actions'
import {connect} from 'react-redux'
import TwoPartTitle from '../../ui/TwoPartTitle/TwoPartTitle'
import NewVacation from '../../components/Vacations/NewVacation/NewVacation'
import UserVacations from '../../components/Vacations/UserVacations/UserVacations'
import Spinner from '../../components/Spinner/Spinner'
import {showModal, hideModal} from '../../store/actions/modals.actions'
import AdminVacations from '../../components/Vacations/AdminVacations/AdminVacations'
import moment from 'moment'
import {getMe} from '../../store/actions/authentication.actions'
import {toast} from 'react-toastify'

class Vacations extends React.Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        this.props.loadUserVacations()
        if (this.props.loggedIn) {
            this.props.getMe()
        }
    }

    getPendingVacations = () => {
        return this.props.vacations.filter(vacation => !vacation.accepted || moment().isBefore(moment(vacation.end)))
    }

    getCompletedVacations = () => {
        return this.props.vacations.filter(
            vacation => vacation.accepted && !vacation.edited && moment().isAfter(moment(vacation.end)),
        )
    }

    getVacationsDaysLeft = () => {
        return this.props.user?.leaveDays > 0 ? `${this.props.user.leaveDays} days` : 'No days left'
    }

    getMaxVacationDays = () => {
        if (!this.props.user?.maxLeaveDays) {
            return 'Days not assigned'
        }

        return this.props.user?.maxLeaveDays > 0
            ? `${this.props.user.maxLeaveDays + this.props.user.additionalLeaveDays} days`
            : 'Days not assigned'
    }

    getUsedVacationDays = () => {
        const maxLeaveDays = this.props.user.maxLeaveDays + this.props.user.additionalLeaveDays

        const usedDays = maxLeaveDays - this.props.user?.leaveDays

        if (usedDays >= this.props.user?.maxLeaveDays) {
            return 'All days used'
        }

        if (usedDays === 0) {
            return 'No days used'
        }

        if (usedDays > 0) {
            return `${usedDays} days`
        }

        return 'Days not assigned'
    }

    onVacationDeleteSuccess = () => {
        this.props.hideModal()
        toast.success('Vacation deleted successfully')
    }

    onVacationDeleteError = () => {
        this.props.hideModal()
    }

    handleVacationDelete = vacation => {
        this.props.showModal(
            {
                open: true,
                message: `Are you sure you want to delete vacation from ${vacation.start} to ${vacation.end}?`,
                confirm: () => {
                    this.props.showModal({open: true, message: 'Deleting...'}, 'spinner')
                    this.props.deleteVacation(vacation.id, this.onVacationDeleteSuccess, this.onVacationDeleteError)
                },
            },
            'confirm',
        )
    }

    handleVacationUpdate = vacation => {
        if (vacation.start instanceof Date) {
            vacation.start = moment(vacation.start).format('YYYY-MM-DD')
        }
        if (vacation.end instanceof Date) {
            vacation.end = moment(vacation.end).format('YYYY-MM-DD')
        }

        const foundVacation = this.props.vacations.find(vac => vac.id === vacation.id)

        if (foundVacation.start === vacation.start && foundVacation.end === vacation.end) {
            return
        }

        this.props.startVacationEdit({
            id: vacation.id,
            start: vacation.start,
            end: vacation.end,
            accepted: vacation.accepted,
        })
    }

    onVacationEditSuccess = () => {
        this.props.hideModal()
        toast.success('Vacation updated successfully')
    }

    onVacationEditError = () => {
        this.props.hideModal()
        this.props.loadUserVacations()
    }

    handleVacationSave = vacation => {
        this.props.showModal({open: true, message: 'Updating...'}, 'spinner')

        this.props.editVacation(
            vacation.id,
            vacation.start,
            vacation.end,
            this.onVacationEditSuccess,
            this.onVacationEditError,
        )
    }

    render() {
        if (!this.props.vacationsLoaded) return <Spinner />

        const pendingVacations = this.getPendingVacations()
        const completedVacations = this.getCompletedVacations()

        return (
            <div className="page-margin my-4">
                <h1 className="mb-4">Leave</h1>
                <TwoPartTitle firstPart="Annual paid leave balance" secondPart={this.getMaxVacationDays()} />

                {this.props.user?.maxLeaveDays ? (
                    <>
                        <TwoPartTitle firstPart="Paid leave left" secondPart={this.getVacationsDaysLeft()} />
                        <TwoPartTitle firstPart="Paid leave used" secondPart={this.getUsedVacationDays()} />
                    </>
                ) : null}

                <h3 className="mt-4">Your pending holidays requests</h3>
                {pendingVacations.length > 0 ? (
                    <UserVacations
                        handleVacationDelete={this.handleVacationDelete}
                        handleVacationUpdate={this.handleVacationUpdate}
                        handleVacationSave={this.handleVacationSave}
                        vacations={pendingVacations}
                    />
                ) : (
                    <p className="mt-3">No pending leave reported</p>
                )}

                <NewVacation />

                <h3 className="mt-4">Completed</h3>

                {completedVacations.length > 0 ? (
                    <UserVacations dateSelectingDisabled={true} vacations={completedVacations} />
                ) : (
                    <p className="mt-3">No completed leave reported</p>
                )}

                {this.props.role === 'Administrator' && (
                    <>
                        <hr />
                        <AdminVacations />
                    </>
                )}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        role: state.auth.user.role,
        leaveDays: state.auth.user.leaveDays,
        vacations: state.vacations.vacations,
        vacationsLoaded: state.vacations.vacationsLoaded,
        loggedIn: state.auth.loggedIn,
        user: state.auth.user,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadUserVacations: () => dispatch(loadUserVacations()),
        deleteVacation: (vacationId, onSuccess, onError) => dispatch(deleteVacation(vacationId, onSuccess, onError)),
        addVacation: (start, end) => dispatch(addVacation(start, end)),
        editVacation: (vacationId, start, end, onSuccess, onError) =>
            dispatch(editVacation(vacationId, start, end, onSuccess, onError)),
        startVacationEdit: (vacationId, data) => dispatch(startVacationEdit(vacationId, data)),
        showModal: (modalProps, modalType) => dispatch(showModal(modalProps, modalType)),
        hideModal: () => dispatch(hideModal()),
        getMe: () => dispatch(getMe()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Vacations)
