import {store} from '../../App'
import axios from 'axios'
import {handleError} from '../../helpers'
import moment from 'moment'

const baseUrl = process.env.REACT_APP_API_URL

export const LOAD_HISTORY = 'LOAD_HISTORY'
export const LOAD_HISTORY_SUCCESS = 'LOAD_HISTORY_SUCCESS'
export const LOAD_HISTORY_FAILURE = 'LOAD_HISTORY_FAILURE'

export const loadHistory = (selectedMonth, selectedYear) => {
    const config = {
        headers: {Authorization: `Bearer ${store.getState().auth.user.token}`},
    }
    const gte = moment().month(selectedMonth).year(selectedYear).startOf('month').format('YYYY-MM-DD')
    const lt = moment().month(selectedMonth).year(selectedYear).add(1, 'months').startOf('month').format('YYYY-MM-DD')

    return dispatch => {
        dispatch({type: LOAD_HISTORY})
        axios
            .get(`${baseUrl}/hours?filters[date][$gte]=${gte}&filters[date][$lt]=${lt}`, config)
            .then(response => {
                dispatch(loadHistorySuccess(response.data, selectedYear))
            })
            .catch(error => {
                dispatch(loadHistoryFailure(error?.response?.data?.error))
            })
    }
}

export const loadHistorySuccess = (hours, year) => {
    return {
        type: LOAD_HISTORY_SUCCESS,
        hours,
        year,
    }
}

export const loadHistoryFailure = error => {
    handleError(error)
    return {
        type: LOAD_HISTORY_FAILURE,
        error: error,
    }
}
