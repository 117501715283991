/* eslint-disable react/prop-types */
import React from 'react'
import NumberInput from '../../../ui/NumberInput/NumberInput'
import SVG from 'react-inlinesvg'
import CustomSelect from '../../../ui/CustomSelect/CustomSelect'
import DataGrid from '../../../ui/DataGrid/DataGrid'

class ProjectDetailsCosts extends React.Component {
    constructor(props) {
        super(props)
    }

    columns = [
        {
            dataField: 'user.username',
            text: 'Employee',
            editable: false,
            formatter: cell => {
                return cell ?? '-- PRACOWNIK USUNIĘTY --'
            },
        },
        {
            dataField: 'employeeLevel.name',
            text: 'Position',
            editable: false,
            editorRenderer: (editorProps, value) => (
                <CustomSelect
                    {...editorProps}
                    selected={this.props.positions.find(opt => opt.label === value)}
                    options={this.props.positions}></CustomSelect>
            ),
        },
        {
            dataField: 'internal',
            text: 'Internal Rate',
            editable: false,
            editorRenderer: (editorProps, value) => <NumberInput onUpdate={editorProps.onUpdate} value={value} />,
        },
        {
            dataField: 'external',
            text: 'External Rate',
            editable: true,
            editorRenderer: (editorProps, value) => <NumberInput onUpdate={editorProps.onUpdate} value={value} />,
        },

        {
            dataField: 'delete',
            isDummyField: true,
            text: 'Archive',
            editable: false,
            formatter: (cellContent, row) => {
                if (!row.archived) {
                    return (
                        <div className="d-flex a-center j-center">
                            <p>No</p>
                            <button
                                className="action-button action-button-circle action-button-pink align-right"
                                onClick={() => this.props.onCostArchive(row)}>
                                <SVG className="icon-circle-white" src="/assets/icons/trash-can.svg" />
                            </button>
                        </div>
                    )
                } else {
                    return (
                        <div>
                            <p>Yes</p>
                        </div>
                    )
                }
            },
        },
    ]

    rowClasses = row => {
        let classes = null
        if (row.archived) {
            classes = 'archived'
        }

        return classes
    }

    onCostUpdate = cost => {
        this.props.update(cost)
    }

    render() {
        return (
            <div>
                <h2>Costs</h2>
                <DataGrid
                    columns={this.columns}
                    data={this.props.costs}
                    updated={this.onCostUpdate}
                    rowClasses={this.rowClasses}
                />
            </div>
        )
    }
}

export default ProjectDetailsCosts
