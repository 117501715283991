import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {toast} from 'react-toastify'

class NumberInput extends Component {
    constructor(props) {
        super(props)
        this.state = {
            value: this.props.value,
        }
    }
    static propTypes = {
        value: PropTypes.number,
        onUpdate: PropTypes.func.isRequired,
    }

    static defaultProps = {
        value: 0,
    }

    handleChangeNumber = e => {
        let {value} = e.target
        const maxNumber = this.props.type === 'hour' ? 12 : 99999999

        // eslint-disable-next-line no-useless-escape
        const regex = /^[0-9]*(\.|\,)?[0-9]*$/

        if (value === '') {
            this.setState({
                ...this.state,
                value,
            })
        } else if (regex.test(value) && parseFloat(value) < maxNumber) {
            this.setState({
                ...this.state,
                value,
            })
        } else if (regex.test(value) && parseFloat(value) > maxNumber) {
            this.setState({
                ...this.state,
                value: maxNumber,
            })
        }
    }

    getValue() {
        if (this.props.type === 'hour') {
            const value = this.control.value ? Math.round(this.control.value / 0.25) * 0.25 : 0.25
            if (value > 12) {
                toast.error('You should not enter more than 12 hours in one entry')
                return 12
            }
            if (value <= 0) {
                toast.error('Value must be greater than 0.25')
                return 0.25
            }
            return value
        }
        return this.control.value > 0 ? parseFloat(this.control.value) : 0
    }

    render() {
        // eslint-disable-next-line no-unused-vars
        const {value, onUpdate, ...rest} = this.props
        return (
            <input
                {...rest}
                value={this.state.value}
                key="control"
                ref={node => (this.control = node)}
                type="number"
                min="0"
                onChange={this.handleChangeNumber}
                onBlur={() => onUpdate(this.getValue())}
            />
        )
    }
}

export default NumberInput
